import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';
const WIDGET_STAGE_API_URL = 'https://backend-stage.estate.hart-digital.com';
const HIDE_PRELOADER_DELAY_AFTER_LOAD = 500;

const hidePreloader = () => {
  const preloader = document.querySelector('.widget-preloader-frame');
  if (preloader) {
    preloader.classList.add('hidden');
  } else {
    console.error('Preloader not found!');
  }
};

const updateScaleText = (scaleButton) => {
  const img = scaleButton.querySelector('img');
  const span = scaleButton.querySelector('span');
  if (img && span) {
    const dataTail05 = 'Ii8+Cjwvc3ZnPgo=';
    span.innerHTML = img.src.endsWith(dataTail05) ? '0.5x' : '1x';
  }
};

const removeButtonsAndHideContainer = () => {
  const controlsContainer = document.querySelector('.widget-tab__panorama-controls');
  const rotationButton = document.querySelector('.widget-tab__panorama-controls-button--rotation');
  const rulerButton = document.querySelector('.widget-tab__panorama-controls-button--ruler');

  const button3D = document.querySelectorAll('.widget-tab-button')[1];
  button3D.addEventListener('click', () => {
    const interval = setInterval(() => {
      const scaleButton = document.querySelector('.widget-tab__panorama-controls-button--scale');
      const rotationButton = document.querySelector('.widget-tab__panorama-controls-button--rotation');
      if (scaleButton && rotationButton) {
        clearInterval(interval);
        rotationButton.remove();
      }
    }, 0.01)
    rotationButton.remove();
  })

  if (rotationButton) {
    rotationButton.remove();
  }

  if (controlsContainer && !rulerButton) {
    controlsContainer.style.display = 'none';
  }
};

const startRulerTime = () => {
  const rulerTime = setInterval(() => {
    const select = document.querySelector('.floors-select');
    const ruler = document.querySelector('.widget-tab__panorama-controls-button--ruler');
    if (select && ruler) {
      select.before(ruler);
      clearInterval(rulerTime);
    }
  }, 100);
};

const startScaleTime = () => {
  const scaleTime = setInterval(() => {
    const select = document.querySelector('.widget-tab-button--active');
    const scale = document.querySelector('.widget-tab__panorama-controls-button--scale');
    if (select && scale) {
      select.after(scale);

      if (!scale.querySelector('span')) {
        const span = document.createElement('span');
        scale.appendChild(span);
        updateScaleText(scale);
      }

      clearInterval(scaleTime);
    }
  }, 100);
};

const startSelectFloorTime = () => {
  const selectTime = setInterval(() => {
    const buttons = document.querySelector('.widget-tab-buttons');
    const select = document.querySelector('.floors-select');
    if (select && buttons) {
      buttons.append(select);
      clearInterval(selectTime);
    }
  }, 100);
};

const copyWidgetEvents = () => {
  document.querySelector('.widget-tab-buttons .widget-tab__isometry-arrow--prev').addEventListener('click', () => {
    document.querySelector('.widget-tab__rotation .widget-tab__isometry-arrow--prev').click();
    document.querySelector('.widget-tab-buttons span').innerHTML = document.querySelector('.widget-tab__rotation span').innerHTML;
  });
  document.querySelector('.widget-tab-buttons .widget-tab__isometry-arrow--next').addEventListener('click', () => {
    document.querySelector('.widget-tab__rotation .widget-tab__isometry-arrow--next').click();
    document.querySelector('.widget-tab-buttons span').innerHTML = document.querySelector('.widget-tab__rotation span').innerHTML;
  });
  document.querySelector('.widget-tab__isometry-button').addEventListener('click', () => {
    document.querySelector('.widget-tab-buttons .widget-tab__isometry-arrows').remove();
    startArrowsTime();
  });
};

const startArrowsTime = () => {
  const arrowsTime = setInterval(() => {
    const buttons = document.querySelector('.widget-tab-buttons');
    const arrows = document.querySelector('.widget-tab__isometry-arrows');
    if (buttons && arrows) {
      buttons.append(arrows.cloneNode(true));
      copyWidgetEvents();
      clearInterval(arrowsTime);
    }
  }, 100);
};

const redesignButtons = () => {
  startSelectFloorTime();
  startRulerTime();
  startScaleTime();
  startArrowsTime();

  document.addEventListener('click', (e) => {
    if (e.target.innerHTML === '2D') {
      const buttons = document.querySelector('.widget-tab-buttons');

      const ruler = document.querySelector('.widget-tab__panorama-controls-button--ruler');
      const scale = document.querySelector('.widget-tab__panorama-controls-button--scale');

      buttons.removeChild(scale);
      startScaleTime();

      if (ruler) {
        buttons.removeChild(ruler);
        startRulerTime();
      }
    }
    else if (e.target.innerHTML === '3D') {
      const buttons = document.querySelector('.widget-tab-buttons');
      const arrows = document.querySelector('.widget-tab__isometry-arrows');
      buttons.removeChild(arrows);
      startArrowsTime();
    }
    else if (e.target.className.endsWith('controls-button--scale')) {
      updateScaleText(e.target);
    }
  });
};

const observeDOM = (callback) => {
  const observer = new MutationObserver(() => {
    if (document.querySelector('.widget-tab__panorama-controls-button--scale')) {
      observer.disconnect();
      callback();
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};

observeDOM(removeButtonsAndHideContainer);

const getText = (lang) => {
  if (lang === 'ru') {
    return 'Отделка и предметы мебели представлены в качестве примера и не входят в состав недвижимости для продажи';
  }

  return 'Apartments serve illustrational purpose only, design may vary';
};

const createWidget = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const env = searchParams.get('env');
  const widgetApiHandler = new ApiStore(
    env?.toLowerCase() === 'stage' ? WIDGET_STAGE_API_URL : WIDGET_API_URL
  );

  const planId = searchParams.get('id');
  const planData = await widgetApiHandler.loadWidgetData(planId);

  const langRu = searchParams.get('lang') === 'ru';
  if (langRu) {
    document.getElementById('widget').classList.add('widget-ru');
  }

  const primaryCameraPointId = searchParams.get('primaryCameraPointId');

  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama', 'rotation'],
    locale: langRu ? 'ru' : 'en',
    colors: {
      main: '#FFD180',
      mainText: '#252525',
    },
    dictionaryOverrides: {
      floor: 'Floor $0',
      'instructions-hint-text': getText(langRu ? 'ru' : 'en')
    },
    scales: ['x05', 'x1'],
    scaleButtonType: 'text',
    primaryCameraPointId
  };

  new Widget('#widget', options);
};

// 'https://www.evometa.com/'
createWidget().then(() => {
  redesignButtons();
  setTimeout(hidePreloader, HIDE_PRELOADER_DELAY_AFTER_LOAD);
});
